import React from "react"
import Layout from "../components/core/Layout"
import SEO from "../components/core/SEO"

const TermsAndConditionsPage: React.FC = () => (
  <Layout activePage="contact-us">
    <SEO title="Terms and Conditions" />
    <h1 className="page-title">Terms and Conditions</h1>
    <div className="divider" />
    <h2 className="section-header">Cancellations</h2>
    <p>
      Cancellations for all of our courses and sessions <b>must</b> be sent in
      writing by email to{" "}
      <a href="mailto:enquiries@greenfieldpottery.co.uk">
        enquiries@greenfieldpottery.co.uk
      </a>
      . If received 14 days or more before the course/session start date, your
      deposit or any fees paid will be refunded in full. Alternatively, it may
      be possible to transfer your booked course to another date, subject to
      availability.
    </p>
    <p>
      Deposits and any fees paid are non-refundable for cancellations received
      within 14 days of the start date for all of our courses and sessions. It
      may still be possible to transfer your session to another date, subject to
      availability. If the course has begun, any fees paid are non-refundable
      for the entire duration of the course, and it will no longer be possible
      to transfer your place to another date.
    </p>
    <p>
      Greenfield Pottery reserves the right to cancel any course or session at
      any time or at short notice. In this event you will be notified as soon as
      possible. If we have to cancel your course or session due to unforeseen
      circumstances, you can choose to transfer to the next available course or
      session at no extra charge, or receive a full refund for any fees paid or
      session time owed.
    </p>
    <p>
      Greenfield Pottery will not be liable for any losses or expenses arising
      from alterations or cancellations.
    </p>
    <h2 className="section-header">Non-attendance</h2>
    <p>
      In the event of non-attendance or lateness to a course or session, it is
      not possible to provide alternative studio time. In this situation, fees
      will not be refunded.
    </p>
    <h2 className="section-header">Collecting Pots</h2>
    <p>
      Your pots need to be fully dry which is done naturally before they can be
      fired. Depending upon weather conditions this may take some time. Once we
      have sufficient pieces to fill the kiln, your work will be fired. Space in
      the kiln will be divided between everyone’s work, depending upon size and
      space. Please be aware that sometimes faults can happen during the firing
      process, which are out of our control, and may damage or affect your work.
    </p>
    <p>
      All finished pottery must be collected within 6 weeks of us notifying you
      that your work is ready for collection, unless arranged otherwise. If your
      work has not been collected after 6 weeks, it will be disposed of. Any
      unfinished work which has not been fired and left at the studio for more
      than 6 weeks will be reclaimed by us and used as we see fit, unless
      arranged otherwise.
    </p>
    <p>
      We will not fire any work that has been made at home or off of our
      premises.
    </p>
    <h2 className="section-header">Your Data</h2>
    <p>
      By contacting Greenfield Pottery, either through our website, email,
      telephone, Facebook or Instagram, you are agreeing to Greenfield Pottery
      contacting you regarding your enquiry. We will not use or share your
      information with any other party.
    </p>
    <h2 className="section-header">Business Hours</h2>
    <p>
      All business hours and prices are subject to change and are at the
      discretion of Greenfield Pottery.
    </p>
    <h2 className="section-header">Kiln Hire</h2>
    <p>
      We <b>do not</b> rent out kiln space or hire out our kilns.
    </p>
    <h2 className="section-header">Parking</h2>
    <p>
      There is ample parking at the studio, which is situated on a farm.
      Greenfield Pottery will not accept any responsibility for damage or
      accidents occurring in the parking area, and vehicles and contents left
      are at your own risk.
    </p>
    <p>
      In addition, if you choose to bring any personal belongings into the
      studio, Greenfield Pottery is not responsible for any damage to, loss, or
      theft of your belongings.
    </p>
    <h2 className="section-header">Health &amp; Safety</h2>
    <p>
      Greenfield Pottery cannot be held responsible for any injuries caused
      through the misuse of the studio or equipment. If you are not sure about
      to operate a piece of equipment, please ask for help from one of the
      tutors before using it.
    </p>
    <p>If you are unsure of anything regarding Health &amp; Safety, please ask one of the tutors.</p>
  </Layout>
)

export default TermsAndConditionsPage
